<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="users"
                class="elevation-1"
                style="background: #f5f5f5"
              >
                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2 ml-2"
                      >All Site Users</v-toolbar-title
                    >

                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="adduser">
                      <v-icon color="white"> mdi-plus </v-icon>
                      Create User
                    </v-btn>
                    <v-menu
                      :close-on-content-click="false"
                      v-model="dialog"
                      scrollable
                      transition="scale-transition"
                      origin="top right"
                      :nudge-left="220"
                      max-width="420px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <v-btn
                          color="primary"
                          dark
                          class="mb-2 mt-2 mr-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-plus</v-icon>
                          Create User
                        </v-btn> -->
                      </template>
                      <v-list style="height: 657px">
                        <v-card-text class="pt-12">
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                dense
                                v-model="editedItem.email"
                                outlined
                                :rules="[
                                  () =>
                                    editedItem.email.length > 0 ||
                                    'Required field',
                                ]"
                                label="Email"
                                type="email"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                type="password"
                                required
                                dense
                                v-model="editedItem.password"
                                outlined
                                label="Password"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                type="password"
                                required
                                dense
                                v-model="editedItem.confirm_password"
                                outlined
                                label="Confirm Password"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12">
                              <v-text-field
                                dense
                                v-model="editedItem.username"
                                outlined
                                label="Full Name"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12">
                              <v-text-field
                                dense
                                v-model="editedItem.phone_number"
                                outlined
                                label="Contact Number"
                                type="number"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12">
                              <v-select
                                dense
                                v-model="editedItem.role"
                                :rules="[
                                  () =>
                                    editedItem.role.length > 0 ||
                                    'Select a role',
                                ]"
                                :items="select_val"
                                label="Select Role"
                                outlined
                              ></v-select>
                            </v-col>
                          </v-row>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red darken-1" text @click="close">
                              Cancel
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card-text>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <!-- <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon> -->
                  <v-icon
                    v-if="item.role !== 'manager'"
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>

                <template v-slot:item.role="{ item }">
                  {{ item.role }}
                </template>

                <template v-slot:item.status="{ item }">
                  <div>
                    <v-btn text color="primary" @click="ViewManager(item)">
                      Detail
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <!-- Add User Dialog -->
    <v-dialog v-model="usercreatedialog" max-width="750">
      <v-card>
        <v-card-title class="font-weight-bold"> Add User </v-card-title>
        <v-card-text>
          <v-form ref="userUploadform" v-model="userCreateFormModel">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="addItem.username"
                  required
                  :rules="[(v) => !!v || 'Name Required']"
                  label="Full Name"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  type="email"
                  v-model="addItem.email"
                  required
                  :rules="[(v) => !!v || 'Email Required']"
                  label="Email"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="addItem.password"
                  type="password"
                  required
                  :rules="[
                    () =>
                      addItem.password.length >= 6 ||
                      'Minimum 6 charecter is required',
                  ]"
                  label="Password"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="addItem.confirm_password"
                  type="password"
                  required
                  :rules="[
                    () =>
                      addItem.confirm_password == addItem.password ||
                      'Two Password Are Not Matched',
                  ]"
                  label="Confirm Password"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="addItem.phone_number"
                  type=""
                  required
                  :rules="[(v) => !!v || 'Contract Number Is Required']"
                  label="Contract Number"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="addItem.role"
                  label="Role"
                  required
                  :rules="[(v) => !!v || 'User Role Is Required']"
                  :items="addItem.select_role"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="addItem.role == 'manager'">
              <v-col cols="12">
                <v-radio-group v-model="addItem.option" row>
                  <v-radio label="Warehouse" value="Warehouse"> </v-radio>
                  <v-radio label="Shop" value="Shop"> </v-radio>
                </v-radio-group>
              </v-col>
              <div v-show="addItem.option == 'Warehouse'">
                <v-select
                  dense
                  v-model="addItem.addhome"
                  :items="warehouses"
                  item-text="warehouse_name"
                  label="Warehouse"
                  outlined
                  chips
                  return-object
                >
                </v-select>
              </div>
              <div v-show="addItem.option == 'Shop'">
                <v-select
                  dense
                  v-model="addItem.addhome"
                  :items="shops"
                  item-text="shop_name"
                  label="Shop"
                  outlined
                  chips
                  return-object
                >
                </v-select>
              </div>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="usercreatedialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            @click="saveconfirm"
            :disabled="!userCreateFormModel"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit User Dialog -->
    <v-dialog v-model="Editusercreatedialog" max-width="750">
      <v-card>
        <v-card-title class="font-weight-bold"> Edit User </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="editedItem.username"
                  required
                  :rules="[(v) => !!v || 'Name Required']"
                  label="Full Name"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  type="email"
                  v-model="editedItem.email"
                  required
                  :rules="[(v) => !!v || 'Email Required']"
                  label="Email"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="editedItem.password"
                  type="password"
                  required
                  :rules="[
                    () =>
                      editedItem.password.length >= 6 ||
                      'Minimum 6 charecter is required',
                  ]"
                  label="Password"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="editedItem.confirm_password"
                  type="password"
                  required
                  :rules="[
                    () =>
                      editedItem.confirm_password == editedItem.password ||
                      'Two Password Are Not Matched',
                  ]"
                  label="Confirm Password"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="editedItem.phone_number"
                  type=""
                  required
                  :rules="[(v) => !!v || 'Contract Number Is Required']"
                  label="Contract Number"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="editedItem.role"
                  label="Role"
                  required
                  :rules="[(v) => !!v || 'User Role Is Required']"
                  :items="select_val"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row v-if="addItem.role == 'manager'">
              <v-col cols="12">
                <v-radio-group v-model="addItem.option" row>
                  <v-radio label="Warehouse" value="Warehouse"> </v-radio>
                  <v-radio label="Shop" value="Shop"> </v-radio>
                </v-radio-group>
              </v-col>
              <div v-show="addItem.option == 'Warehouse'">
                <v-select
                  dense
                  v-model="addItem.addhome"
                  :items="warehouses"
                  item-text="warehouse_name"
                  label="Warehouse"
                  outlined
                  chips
                  return-object
                >
                </v-select>
              </div>
              <div v-show="addItem.option == 'Shop'">
                <v-select
                  dense
                  v-model="addItem.addhome"
                  :items="shops"
                  item-text="shop_name"
                  label="Shop"
                  outlined
                  chips
                  return-object
                >
                </v-select>
              </div>
            </v-row> -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="Editusercreatedialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="saveeditUser"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    menu: false,
    menu2: false,
    dialog: false,
    snackbar: false,
    usercreatedialog: false,
    userCreateFormModel: false,
    Editusercreatedialog: false,
    warehouses: [],
    shops: [],
    color: "",
    text: "",
    select_val: ["Staff", "Admin", "Seller"],
    headers: [
      // { text: "User Id", value: "", sortable: false },
      { text: "Full Name", value: "username" },
      { text: "Email", value: "email" },
      { text: "Contact Number", value: "phone_number" },
      { text: "Role", value: "role" },
      { text: "Details", value: "status" , align: 'center' }
    ],
    users: [],
    contact: {},
    editedIndex: -1,
    editedItem: {
      email: "",
      password: "",
      confirm_password: "",
      role: "",
      username: "",
      phone_number: "",
    },
    defaultItem: {
      email: "",
      password: "",
      confirm_password: "",
      role: "",
      username: "",
      phone_number: "",
      is_staff: true,
    },
    addItem: {
      username: "",
      email: "",
      password: "",
      confirm_password: "",
      phone_number: "",
      select_role: ["manager"],
      option: "",
      addhome: {},
    },
    user: {},
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    initialize() {
      axios
        .get(`user/show_merchant_users/${this.user.user_id}/`)
        .then((response) => {
          if (response.data.success && Array.isArray(response.data.data)) {
            this.users = response.data.data;
          }
        })
        .catch((err) => {});
    },

    getWarehouses() {
      axios
        .get(`productdetails/warehouses/?mechant_id=${this.user.user_id}`)
        .then((response) => {
          if (response.data.success) {
            this.warehouses = response.data.data;
          }
        })
        .catch((err) => {});
    },

    getShops() {
      axios
        .get(`productdetails/shops/?mechant_id=${this.user.user_id}`)
        .then((response) => {
          if (response.data.success) {
            this.shops = response.data.data;
          }
        })
        .catch((err) => {});
    },

    makeInactive(item) {
      if (confirm("Do you really want to deactive this ?")) {
        axios
          .post(`user/update_user/${item.id}/`, {
            email: item.email,
            password: item.pwd,
            is_active: false,
          })

          .then((response) => {
            if (response.data.success) {
              this.text = "User is inactivated successfully";
              this.color = "success";
              location.reload();
              return false;
            } else {
              this.text = "Something went wrong !!";
              this.color = "error";
            }
            this.snackbar = true;
          })
          .catch((err) => {
            this.text = "Something went wrong !!";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },

    makeActive(item) {
      if (confirm("Do you really want to deactive this ?")) {
        axios
          .post(`user/update_user/${item.id}/`, {
            email: item.email,
            password: item.pwd,
            is_active: true,
          })

          .then((response) => {
            if (response.data.success) {
              this.text = "User is activated successfully";
              this.color = "success";
              location.reload();
            } else {
              this.text = "Something went wrong !!";
              this.color = "error";
            }
            this.snackbar = true;
          })
          .catch((err) => {
            this.text = "Something went wrong !!";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.Editusercreatedialog = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios
          .post(`user/user_delete/${item.id}/`)
          .then((response) => {
            if (response.data.success) {
              this.users = this.users.filter(
                (contact) => contact.id !== item.id
              );
              this.text = "User has been deleted successfully";
              this.color = "success";
            } else {
              this.text = "Something went wrong !!";
              this.color = "error";
            }
            this.snackbar = true;
          })
          .catch((err) => {
            this.text = "Something went wrong !!";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        let values = this.editedItem;
        let index = this.editedIndex;

        axios
          .post(`user/update_user/${this.editedItem.id}/`, {
            email: values.email,
            password: values.pwd,
            role: values.role,
          })
          .then((response) => {
            Object.assign(this.users[index], values);
            this.Editusercreatedialog = false;
          });
      } else {
        let all_data = this.editedItem;

        if (
          this.editedItem.email &&
          this.editedItem.password &&
          this.editedItem.role
        ) {
          axios
            .post("user/create_user/", this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.users.push(response.data.data);
                this.text = "user has been created successfully";
                this.color = "success";
              } else {
                this.text = "Something went wrong !!";
                this.color = "error";
              }
              this.snackbar = true;
            })
            .catch((err) => {
              this.text = "Something went wrong !!";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          this.text = "Fill up all the field first !!";
          this.color = "error";
          this.snackbar = true;
        }
      }
      this.close();
    },
    adduser() {
      this.usercreatedialog = true;
    },
    saveconfirm() {
      if (this.addItem.role == "manager") {
        if (this.addItem.option == "Shop") {
          let datas = {
            email: this.addItem.email,
            username: this.addItem.username,
            phone_number: this.addItem.phone_number,
            shop_id: this.addItem.addhome.id,
            password: this.addItem.password,
            role: this.addItem.role,
          };

          axios.post("merchant/merchant_manager_create/", datas).then((response) => {
            if (response.data.success) {
              this.text = response.data.message;
              this.color = "success";
              this.snackbar = true;
              this.usercreatedialog = false;
              this.$refs.userUploadform.reset();

              setTimeout(() => {
                location.reload();
              }, 1000);
            }
          });
        } else {
          let datas = {
            email: this.addItem.email,
            username: this.addItem.username,
            phone_number: this.addItem.phone_number,
            warehouse_id: this.addItem.addhome.id,
            password: this.addItem.password,
            role: this.addItem.role,
          };
          // console.log("this is array after click" , datas)
          axios.post("merchant/merchant_manager_create/", datas).then((response) => {
            if (response.data.success) {
              this.text = response.data.message;
              this.color = "success";
              this.snackbar = true;
              this.usercreatedialog = false;

              setTimeout(() => {
                location.reload();
              }, 1000);
            }
          });
        }
      } else {
        axios
          .post("user/create_user/", this.addItem)
          .then((response) => {
            //console.log("response data", response.data);
            if (response.data.success) {
              this.users.push(response.data.data);
              this.text = response.data.message;
              this.color = "success";
              this.snackbar = true;
              this.usercreatedialog = false;
            } else {
              this.text = response.data.message;
              this.color = "error";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    saveeditUser(item) {
      let values = {
        email: this.editedItem.email,
        password: this.editedItem.pwd,
        role: this.editedItem.role,
        phone_number: this.editedItem.phone_number,
        username: this.editedItem.username,
      };
      axios
        .post(`user/update_user/${this.editedItem.id}/`, values)
        .then((response) => {
          Object.assign(this.users[index], values);
          this.Editusercreatedialog = false;
        });
    },
    ViewManager(item) {
      let routeData = this.$router.resolve({
        name: `/merchantdashboard/pages/manageruserdetails/${item.id}`,
      });
      routeData.href = `/merchantdashboard/pages/manageruserdetails/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user.role && this.user.role.toLowerCase() == "seller") {
      this.initialize();
      this.getWarehouses();
      this.getShops();
    }
  },
};
</script>


<style>
.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

